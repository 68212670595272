<template>
  <div class="partyOrg">
    <div class="img"><img src="./img/org1.png" alt="" /></div>
    <div class="img"><img src="./img/org2.png" alt="" /></div>
    <div class="img"><img src="./img/org3.png" alt="" /></div>
    <div class="img"><img src="./img/org4.png" alt="" /></div>
    <div class="img"><img src="./img/org5.png" alt="" /></div>
    <div class="img"><img src="./img/org6.png" alt="" /></div>
  </div>
</template>

<script>
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "partyOrg",
  data() {
    return {};
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.partyOrg {
  min-height: 100vh;
  .img {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
.partyOrg {
}
</style>
